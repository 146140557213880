/**
 * External dependencies.
 */
import { GetStaticProps } from "next";
import { t, Trans } from "@lingui/macro";

/**
 * Internal dependencies.
 */
import Layout from "layout/layout";
import { handleBaseLocales, loadTranslation } from "utils";
import Link from "components/link/link";
// import HreflangControlled from "blocks/hreflang/hreflang-controlled";

const ErrorPage = ({ locales }: any) => {
  return (
    <Layout title="404" desc="404" notice hreflang={false}>
      {/* <HreflangControlled localizations={locales} /> */}
      <div className="ta-c">
        <h4 style={{ marginTop: 50, marginBottom: 20 }}>
          <Trans>The page can't be found</Trans>
        </h4>
        <p>
          <Trans>Please click</Trans> <Link href="/">{t`here`}</Link>{" "}
          <Trans>to return to the homepage</Trans>
        </p>
      </div>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const translation = await loadTranslation(
    ctx.locale!,
    process.env.NODE_ENV === "production"
  );

  return {
    props: {
      translation,
      // locales: handleBaseLocales(ctx.locales),
    },
    revalidate: 10,
  };
};

export default ErrorPage;
